<template>
    <div class="pt-32 flex flex-wrap" v-if="user">
        <div class="md:w-1/2 w-full mb-12">
            <class-details-card v-if="classDetails" :class-details="classDetails" :lang-code="'zh'" :my-theme="myTheme"
                ref="classDetailsRef" :show-credit="true" />
        </div>
        <div class="md:w-1/2 w-full flex flex-col items-center" v-if="isManager || isTeacher">
            <div class="w-full flex flex-col mb-8" v-if="classDetails">
                <div v-for="(plan, index) in planList" :key="index">
                    <van-cell-group class="shadow-md mb-8" inset v-if="planIndex !== index">
                        <van-cell :title="plan.action" :value="plan.code" :label="plan.info" is-link
                            @click="editPlan(index)" />
                    </van-cell-group>
                    <van-cell-group class="shadow-md mb-8" inset v-if="planIndex === index">
                        <van-field v-model="plan.action" label="计划名称" placeholder="请输入计划名称动作" />
                        <van-field v-model="plan.info" label="计划详情" placeholder="请输入计划说明文字" type="textarea" maxlength="100"
                            show-word-limit />
                        <van-field label="线下支付">
                            <template #input>
                                <van-switch v-model="plan.offline" size="20" @change="switchLine(plan)" />
                            </template>
                        </van-field>
                        <van-field label="用户输入" v-if="plan.offline">
                            <template #input>
                                <van-switch v-model="plan.usingInput" size="20" @change="switchInput(plan)" />
                            </template>
                        </van-field>
                        <van-field v-if="plan.usingInput" v-model="plan.input.mask" type="text" label="数字单位" />
                        <van-field v-if="plan.usingInput" v-model.number="plan.input.min" type="number" label="最小" />
                        <van-field v-if="plan.usingInput" v-model.number="plan.input.max" type="number" label="最大" />
                        <van-field v-if="plan.usingInput" v-model="plan.input.template" type="text" label="金额模版" />
                        <van-field v-model="plan.code" type="text" label="访问码" placeholder="无访问码则用户皆可访问"
                            :formatter="textFormatter" />
                        <van-field v-if="!plan.offline" v-model="plan.link" type="text" label="支付链接" />
                        <van-field v-if="plan.offline" v-model.number="plan.cost" type="number" label="金额"
                            :formatter="moneyFormatter">
                            <template #right-icon>
                                $
                            </template>
                        </van-field>
                        <van-field v-if="plan.offline && !classDetails.teachers.length" v-model.number="plan.credit"
                            type="number" label="Credit" />
                        <van-cell>
                            <template #value>
                                <div class="flex flex-row-reverse">
                                    <van-button class="ml-4 mb-2" plain type="danger" size="small"
                                        @click="deletePlan(index)">删除</van-button>
                                    <van-button class="ml-4 mb-2" plain type="primary" size="small"
                                        @click="confirmEdit()">提交修改</van-button>
                                    <van-button class="ml-4 mb-2" plain size="small" @click="cancelEdit()">取消</van-button>
                                </div>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>

                <van-button v-if="planIndex < 0" class="mt-8 mx-4" type="primary" @click="addPlan()">添加支付计划</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    Cell,
    CellGroup,
    Button,
    Field,
    Icon,
    Toast,
    Dialog,
    Switch
} from "vant";
import UserTypes from "@/static_values/StaticUserTypes.js";
import myTheme from "@/logics/MyVantTheme.js";
import moneyFormatter from "@/logics/FormatterMoney.js";
import textFormatter from "@/logics/FormatterTextInput.js";
import ClassDetailsCard from "@/components/ClassDetailsCard.vue";
import LoadClass from "@/asyncs/LoadClass.js";
import GetSession from "@/asyncs/GetSession.js";
import UpdateClassPay from "@/asyncs/UpdateClassPayPlans.js";
import DeletePlan from "@/asyncs/DeletePlan.js";

export default {
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [Field.name]: Field,
        [Icon.name]: Icon,
        [Switch.name]: Switch,
        ClassDetailsCard
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("setPageTitle", "课程支付");
        const route = useRoute();
        const router = useRouter();
        const classDetails = ref(null);
        const planList = ref([]);
        const classDetailsRef = ref(null);
        const setupData = function () {
            classDetails.value.payPlans.forEach(function (plan) {
                const copy = JSON.parse(JSON.stringify(plan));
                if (copy.input) {
                    copy.usingInput = true;
                }
                planList.value.push(copy);
            });
        }
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                if (!store.state.classDetails) {
                    classDetails.value = await LoadClass(route.params.classId);
                    if (!classDetails.value) {
                        return;
                    }
                } else {
                    classDetails.value = store.state.classDetails;
                }
                setupData();
            });
        });

        const planIndex = ref(-1);
        const editPlan = function (index) {
            if (planIndex.value >= 0) {
                cancelEdit(planIndex.value);
            }
            planIndex.value = index;
        };
        const cancelEdit = function () {
            if (planIndex.value >= classDetails.value.payPlans.length) {
                planList.value.pop();
                planIndex.value = -1;
                return;
            }

            const copy = JSON.parse(JSON.stringify(classDetails.value.payPlans[planIndex.value]));
            if (copy.input) {
                copy.usingInput = true;
            }
            planList.value.splice(planIndex.value, 1, copy);
            planIndex.value = -1;
        };
        const confirmEdit = async function () {
            if (planIndex.value < 0) {
                alert("What?");
                return;
            }
            try {
                await Dialog.confirm({
                    title: "确定提交修改吗？",
                    confirmButtonColor: "#ee0a24"
                });
                const copy = JSON.parse(JSON.stringify(planList.value[planIndex.value]));
                delete copy.type;
                delete copy.usingInput;
                classDetails.value.payPlans.splice(planIndex.value, 1, copy);

                const error = await UpdateClassPay(classDetails.value.classId, classDetails.value.payPlans);
                if (error) {
                    Toast.fail("提交修改失败");
                } else {
                    Toast.success("提交修改成功");
                    planIndex.value = -1;
                }
            } catch (e) {
                console.log(e);
            }
        };
        const switchLine = function (plan) {
            if (plan.offline) {
                plan.cost = 0;
                plan.usingInput = false;
                delete plan.link;
                if (!classDetails.value.teachers.length) {
                    plan.credit = 0;
                }
            } else {
                plan.link = "https://";
                delete plan.cost;
                delete plan.credit;
                delete plan.usingInput;
                delete plan.input;
            }
        };
        const switchInput = function (plan) {
            if (!plan.offline) {
                alert("Something is wrong");
                return;
            }
            if (plan.usingInput) {
                plan.input = {
                    min: 4,
                    max: 40,
                    mask: "10;10",
                    template: "{0}-{1}"
                };
            } else {
                delete plan.input;
            }
        };
        const addPlan = function () {
            // if (classDetails.value.teachers.length) {
            //     Toast.fail("抱歉，系统暂时还不能给[教学课程]添加支付计划，请跟莘莘探讨一下");
            //     return;
            // }
            if (planList.value.length !== classDetails.value.payPlans.length) {
                alert("something wrong");
                return;
            }
            if (planIndex.value >= 0) {
                cancelEdit(planIndex.value);
            }

            planList.value.push({
                action: "",
                info: "",
                offline: false,
                code: "",
                link: "https://"
            });
            planIndex.value = planList.value.length - 1;
        };
        const deletePlan = async function (index) {
            const plan = planList.value[index];
            try {
                await Dialog.confirm({
                    title: "删除支付计划",
                    message: `确定要删除 [${plan.action}] 支付计划吗？`,
                    confirmButtonColor: "#ee0a24"
                });
            } catch (e) {
                console.log(e);
                return;
            }
            planIndex.value = -1;
            const error = await DeletePlan(classDetails.value.classId, index);
            if (error) {
                Toast.fail(error);
            } else {
                Toast.success("已删除成功");
                planList.value.splice(index, 1);
                classDetails.value.payPlans.splice(index, 1);
            }
        };

        return {
            myTheme,
            classDetails,
            classDetailsRef,
            planList,
            editPlan,
            cancelEdit,
            confirmEdit,
            planIndex,
            addPlan,
            moneyFormatter,
            textFormatter,
            deletePlan,
            switchLine,
            switchInput,
            user: computed(() => store.state.user),
            isManager: computed(() => UserTypes.manager.value === store.state.user.userType),
            isTeacher: computed(() => UserTypes.teacher.value === store.state.user.userType)
        };
    }
}
</script>
