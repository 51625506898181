import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId, planIndex) {
    try {
        const params = { classId, planIndex };
        const res = await axios.get(Env.apiPath + "deletePayPlan", { params });
        return res.data.error;
    } catch (err) {
        return err;
    }
}